import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

function SEO({
  description,
  lang,
  meta,
  keywords = [
    "javascript",
    "sheffield",
    "react",
    "node",
    "uk",
    "consultancy",
    "consultant",
    "node.js"
  ],
  title,
  name,
  description: contentfulDescription,
  twitterHandle
}) {
  const metaDescription = description || contentfulDescription;
  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title ? title : name}
      titleTemplate={title ? `%s | ${name}` : "%s"}
      meta={[
        {
          name: "description",
          content: metaDescription
        },
        {
          property: "og:title",
          content: title
        },
        {
          property: "og:description",
          content: metaDescription
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          name: "twitter:card",
          content: "summary"
        },
        {
          name: "twitter:creator",
          content: twitterHandle
        },
        {
          name: "twitter:title",
          content: name
        },
        {
          name: "twitter:description",
          content: metaDescription
        }
      ]
        .concat(
          keywords.length > 0
            ? {
                name: "keywords",
                content: keywords.join(", ")
              }
            : []
        )
        .concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: "en",
  meta: [],
  keywords: []
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string
};

export default SEO;
